import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import responsibleConf from './components/reimbursementConf.vue';
import reimbursementModal from './components/reimbursementModal.vue';
import reimbursementDetailModal from './components/reimbursementDetailModal.vue';
import Cookies from "js-cookie";
import { selectWarntime, updateWarntime, selectVerifierSetting, selectApprovalItem, setTemporaryTransferState, setTemporaryTransfer, selectApproachByModular, selectJobCascade, deleteVerifierSetting } from '@/api/material';
import { selectExpenseApplyByPagination } from '@/api/officeApi';
import moment from 'moment';
import { useStore } from 'vuex';
import { QuestionCircleOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
import reimbursementType from './components/reimbursementType.vue'; //表头

const columns = [{
  title: '主题',
  dataIndex: 'theme'
}, {
  // title: '申请时间',
  dataIndex: 'createTime',
  slots: {
    title: 'customTitle'
  }
}, {
  title: '申请人',
  dataIndex: 'userName'
}, {
  title: '审批状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const responsibleColumns = [{
  title: '名称',
  dataIndex: 'itemName'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const transferColumns = [{
  title: '原审批责任人',
  dataIndex: 'sourceName'
}, {
  title: '临时审批责任人',
  dataIndex: 'targetName'
}, {
  title: '转移期限',
  dataIndex: 'time'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    responsibleConf,
    reimbursementModal,
    reimbursementDetailModal,
    QuestionCircleOutlined,
    reimbursementType,
    CaretUpOutlined,
    CaretDownOutlined
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      state: null,
      myProcess: null,
      userName: null,
      data: []
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      orderly.value = null;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    let orderly = ref(null);

    const getTableData = async () => {
      const [startTime, endTime] = formState.data.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD');
        }

        return item.startOf('day').format('YYYY-MM-DD');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        state: formState.state ? formState.state : null,
        myProcess: formState.myProcess ? formState.myProcess : null,
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null,
        userName: formState.userName,
        orderly: orderly.value
      };
      loading.value = true;
      let res = await selectExpenseApplyByPagination(param);

      if (res.code === 200) {
        const arr = [];
        tableData.value = [];
        if (res.data.list) res.data.list.forEach(item => {
          arr.push({
            key: item.id,
            theme: item.theme,
            createTime: item.createTime.split(' ')[0],
            userName: item.userName,
            state: item.state,
            isOperate: item.isOperate,
            isShow: item.isShow
          });
        });
        Object.assign(tableData.value, arr);
        total.value = res.data.countSize;
        current.value = res.data.nowPage;
        pageSize.value = res.data.pageSize;
        loading.value = false;
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //责任人配置


    const respVisible = ref(false);
    const respData = reactive({
      rests: [],
      finances: [],
      notifiers: [],
      itemId: null,
      itemName: null
    });

    const handleConf = async () => {
      const param = {
        type: 8,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        const arr = [];
        const arr1 = [];
        projectByResponsible.value = [];
        transferData.value = [];
        if (res.data.approvalItems && res.data.approvalItems.length) res.data.approvalItems.forEach(item => {
          arr.push({
            key: item.id,
            ...item
          });
        });
        Object.assign(projectByResponsible.value, arr);
        if (res.data.approvalTransfers && res.data.approvalTransfers.length) res.data.approvalTransfers.forEach(item => {
          arr1.push({
            key: item.id,
            ...item,
            time: `${item.startTime}-${item.endTime}`
          });
        });
        Object.assign(transferData.value, arr1);
        responsibleVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const responsibleConf = itemId => {
      Promise.all([getVerifierSetting(itemId)]).then(res => {
        // console.log('Promise.all',res)
        try {
          respData.rests = getJobData(res[0].rests);
          respData.finances = getJobData(res[0].finances);
          respData.notifiers = getJobData(res[0].notifiers);
          respData.itemId = res[0].itemId;
          respData.itemName = res[0].itemName;
          nextTick(() => {
            respVisible.value = true;
          });
        } catch (error) {
          console.log(error);
        }
      });
    };

    const getJobData = arr => {
      let respData = [];
      arr.forEach(item => {
        respData.push([item.jobId, `${item.idCard}-${item.projectId}-${item.userName}`]);
      });
      return respData;
    }; // 查询审批责任人信息


    const getVerifierSetting = id => {
      return new Promise((resolve, reject) => {
        const param = {
          type: 8,
          belongProject: Number(Cookies.get("belongProject")),
          itemId: id ? id : null
        };
        selectVerifierSetting(param).then(res => {
          resolve(res.data);
        }).catch(error => {
          console.log('查询审批责任人信息', error);
        });
      });
    }; //关闭责任人弹窗


    const closeRespVisible = val => {
      respVisible.value = val;
      handleConf();
    }; //发起申请


    const applyVisible = ref(false);

    const handleApply = () => {
      applyVisible.value = true;
    }; //关闭申请弹窗


    const closeApplyVisible = val => {
      applyVisible.value = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    }; //详情


    const applyDetailVisible = ref(false);
    const applyDetailData = reactive({
      state: 1,
      // data:{}
      key: null,
      applyVisible: false
    }); //查看详情

    const toView = async (item, num) => {
      // console.log(item,num) //num 0详情 2修改 1审批 
      applyDetailData.state = num;
      applyDetailData.key = item.key;
      applyDetailData.applyVisible = true;
    }; //关闭详情页


    const closeApplyDetailVisible = val => {
      // applyDetailVisible.value = val.isClose
      // applyDetailData.state = 1
      // applyDetailData.data = {}
      applyDetailData.applyVisible = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    };

    const updateDataValue = val => {
      applyDetailData.data = val;
    }; //审批时间设置


    const timeVisible = ref(false);
    const timeFormRef = ref();
    const timeFormState = reactive({
      intervalTime: null,
      circulateTime: null,
      id: null,
      type: null,
      belongProject: null,
      isOpenOvertime: false
    });

    const handleTimeConf = async () => {
      const param = {
        type: 8,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectWarntime(param);

      if (res.code === 200) {
        Object.assign(timeFormState, res.data);
        timeFormState.isOpenOvertime = timeFormState.isOpenOvertime ? true : false;
        timeVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const handleSetTime = () => {
      timeFormRef.value.validate().then(async () => {
        // console.log(timeFormState)
        if (timeFormState.isOpenOvertime && timeFormState.circulateTime <= timeFormState.intervalTime) {
          _message.error('流转时间必须大于提示时间！');

          return;
        }

        const param = {};
        Object.assign(param, timeFormState);
        param.isOpenOvertime = param.isOpenOvertime ? 1 : 0;
        let res = await updateWarntime(param);

        if (res.code === 200) {
          _message.success(res.message);

          timeVisible.value = false;
        } else {
          _message.error(res.message);
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const handleCancel = () => {
      timeVisible.value = false;
      timeFormRef.value.resetFields();
    }; //审批责任人项目列表


    const responsibleVisible = ref(false);
    const projectByResponsible = ref([]);
    const transferData = ref([]); //取消权限转移

    const cancelTransfer = async id => {
      let res = await setTemporaryTransferState({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        handleConf();
      } else {
        _message.error(res.message);
      }
    }; //新增权限转移


    const addTransferVisible = ref(false);

    const addTransfer = () => {
      addTransferVisible.value = true;
    };

    const formRefForModelTransfer = ref(false);
    const formStateForModelTransfer = reactive({
      sourceUid: null,
      targetUid: [],
      time: []
    });

    const handleCancelTransfer = () => {
      formRefForModelTransfer.value.resetFields();
      addTransferVisible.value = false;
    };

    const adTransferLoading = ref(false);

    const handleOkTransfer = () => {
      console.log(formStateForModelTransfer);
      formRefForModelTransfer.value.validate().then(async () => {
        adTransferLoading.value = true;

        try {
          let param = {
            sourceUid: formStateForModelTransfer.sourceUid,
            targetUid: formStateForModelTransfer.targetUid[1],
            startTime: formStateForModelTransfer.time[0],
            endTime: formStateForModelTransfer.time[1],
            modularType: 8,
            belongProject: Number(Cookies.get("belongProject"))
          };
          let res = await setTemporaryTransfer(param);

          if (res.code === 200) {
            _message.success(res.message);

            adTransferLoading.value = false;
            formRefForModelTransfer.value.resetFields();
            addTransferVisible.value = false;
            handleConf();
          } else {
            _message.error(res.message);

            adTransferLoading.value = false;
          }
        } catch (error) {
          console.log(error);
          adTransferLoading.value = false;
        }
      });
    };

    const transferOption = ref([]);

    const getTransferOption = async () => {
      let param = {
        type: 8,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApproachByModular(param);

      if (res.code === 200) {
        transferOption.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            transferOption.value.push({
              value: item.idCard,
              label: item.userName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const personnelData = ref([]);

    const getPersonnelData = async val => {
      let param = {
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectJobCascade(param);

      if (res.code === 200) {
        const jobProjectsAll = res.data.jobProjectsAll;
        personnelData.value = conversion(jobProjectsAll); // if(res.data && res.data.length) {
        //   res.data.forEach(item => {
        //     personnelData.value.push({
        //       value: item.idCard,
        //       label: item.userName,
        //     })
        //   })
        // }
      } else {
        _message.error(res.message);
      }
    };

    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            obj.children.push({
              value: ixt.uid,
              label: ixt.userName,
              projectId: ixt.belongProject
            });
          });
        }

        arr.push(obj);
      });
      return arr;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const rules = {
      sourceUid: [{
        required: true,
        message: '原审批责任人不能为空',
        trigger: 'change'
      }],
      targetUid: [{
        required: true,
        message: '临时转移人不能为空',
        trigger: 'change',
        type: 'array'
      }],
      time: [{
        required: true,
        message: '转移期限不能为空',
        trigger: 'change',
        type: 'array'
      }]
    };

    const deleteConf = async itemId => {
      try {
        let param = {
          itemId,
          modularType: 8
        };
        let res = await deleteVerifierSetting(param);

        if (res.code == 200) {
          _message.success(res.message);

          handleConf();
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const labelVisible = ref(false);

    const clickFlag = val => {
      orderly.value = val;
      getTableData();
    };

    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      handleConf,
      handleApply,
      respVisible,
      closeRespVisible,
      applyVisible,
      closeApplyVisible,
      toView,
      applyDetailVisible,
      closeApplyDetailVisible,
      handleTimeConf,
      timeVisible,
      handleSetTime,
      timeFormState,
      timeFormRef,
      handleCancel,
      respData,
      applyDetailData,
      isCompanyAdmin,
      updateDataValue,
      responsibleVisible,
      projectByResponsible,
      responsibleColumns,
      responsibleConf,
      transferColumns,
      transferData,
      cancelTransfer,
      addTransfer,
      addTransferVisible,
      formRefForModelTransfer,
      formStateForModelTransfer,
      handleCancelTransfer,
      handleOkTransfer,
      adTransferLoading,
      transferOption,
      getTransferOption,
      personnelData,
      getPersonnelData,
      disabledDate,
      rules,
      deleteConf,
      labelVisible,
      orderly,
      clickFlag
    };
  }

});