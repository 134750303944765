import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { ProfileOutlined, DollarOutlined, BranchesOutlined, PartitionOutlined, ImportOutlined, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import getFieldRules from '@/utils/fieldRules';
import moment from 'moment';
import { selectTravelApplyById, updateEvectionApply, updateTravelToApproval, selectCostCenterByParam } from '@/api/officeApi'; // import { useStore } from 'vuex'

import leaveTable from './leaveTable.vue';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    ProfileOutlined,
    DollarOutlined,
    uploadImgs,
    BranchesOutlined,
    PartitionOutlined,
    ImportOutlined,
    leaveTable,
    ExclamationCircleOutlined,
    DeleteOutlined,
    PlusOutlined
  },
  props: {
    // applyVisible: {
    //   type: Boolean,
    //   default: false
    // },
    travelApplyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closetravelApplyVisible'],

  setup(props, context) {
    // const store = useStore()
    const formRef = ref();
    const formState = reactive({
      // theme:"",
      // bourn:undefined,
      // evectionCause:undefined,
      // vehicle:undefined,
      // departureTime:undefined,
      returnTime: undefined,
      belongContract: undefined,
      repairReason: "",
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      content: "",
      //审批意见
      itemId: null,
      detailsList: []
    });
    const {
      validateTime
    } = getFieldRules();
    const rules = {
      // theme: [
      //   {
      //     required: true,
      //     message: '申请主题不能为空',
      //     trigger: 'blur',
      //   },
      //   {
      //     pattern: /^[^\s]*$/,
      //     message: '禁止输入空格',
      //     trigger: 'blur',
      //   }
      // ],
      bourn: [{
        required: true,
        message: '目的地不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      evectionCause: [{
        required: true,
        message: '出差事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      vehicle: [{
        required: true,
        message: '交通工具不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      departureTime: [{
        required: true,
        message: '出发日期不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      returnTime: [{
        required: true,
        message: '返回日期不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      costName: [{
        required: true,
        message: '成本中心不能为空',
        trigger: 'change'
      }],
      repairReason: [{
        required: true,
        message: '补报出差申请理由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };

    const closetravelApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closetravelApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const isDisabledForCl = computed(() => {
      if (props.travelApplyDetailData.state === 0) {
        //详情
        return true;
      } else if (props.travelApplyDetailData.state == 1) {
        //审批
        return true;
      } else if (props.travelApplyDetailData.state == 2) {
        //修改
        return false;
      }
    });

    const getData = async key => {
      const res = await selectTravelApplyById(key);

      try {
        Object.assign(formState, res.data);
        console.log(formState);
      } catch (error) {
        console.log('出差详情:', error);
      }
    }; //审批


    const handlePassOrReject = async (num, type) => {
      if (!formState.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.applyId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject
      };
      loading.value = true;

      try {
        const res = await updateTravelToApproval(param);

        if (res.code == 200) {
          _message.success(res.message);

          loading.value = false;
          context.emit('closetravelApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }
      } catch (error) {
        loading.value = false;
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "审批责任人";

        case 4:
          return "财务审批人";

        case 5:
          return "人事审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //修改

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        let flag = formState.detailsList.findIndex(item => {
          return moment(item.departureTime).valueOf() > moment(formState.returnTime).valueOf();
        });

        if (flag >= 0) {
          _message.error('出发日期不能大于返回日期');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        loading.value = true;
        let res = await updateEvectionApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closetravelApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    }; //成本中心查询


    const costCenterOptions = ref([]);

    const getCostCenter = async () => {
      let res = await selectCostCenterByParam({
        costName: null
      });

      if (res.code === 200) {
        costCenterOptions.value = [];
        if (res.data) res.data.forEach(item => {
          costCenterOptions.value.push({
            value: item.costName,
            label: item.costName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; // 是否显示补报出差理由


    const isShowRepairReason = computed(() => {
      let flag = -1;
      flag = formState.detailsList.findIndex(item => {
        return moment(item.departureTime).valueOf() < moment(moment().format('YYYY-MM-DD')).valueOf();
      });

      if (flag == -1) {
        formState.repairReason = null;
        return false;
      } else {
        return true;
      }
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 10,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const addList = () => {
      formState.detailsList.push({
        bourn: null,
        evectionCause: null,
        vehicle: null,
        departureTime: null
      });
    };

    const deleteList = index => {
      if (formState.detailsList.length === 1) {
        _message.error('出差明细不能全部删除!');

        return;
      }

      formState.detailsList.splice(index, 1);
    };

    onMounted(() => {
      getData(props.travelApplyDetailData.key);
      getCostCenter();
      getApprovalItem();
    });
    return {
      formRef,
      formState,
      rules,
      isDisabledForCl,
      closetravelApplyVisible,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      handleOk,
      loading,
      disabledDate,
      costCenterOptions,
      isShowRepairReason,
      projectOptions,
      addList,
      deleteList
    };
  }

});