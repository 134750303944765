import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { ProfileOutlined, DollarOutlined, BranchesOutlined, PartitionOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { updateTeamByApplyId } from '@/api/contract';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import getFieldRules from '@/utils/fieldRules';
import moment from 'moment';
import { selectExpenseApplyById, updateToExpenseApproval, updateReviseApply, updateExpenseApply, selectEvectionApply, selectExpenseTypeByParam } from '@/api/officeApi';
import travelDetailModal from './travelDetailModal.vue';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
import printJS from 'print-js'; // import { useStore } from 'vuex'
// const columns = [
//   {
//     title: '序号',
//     dataIndex: 'id',
//     slots: {
//       customRender: 'id',
//     },
//   },
//   {
//     title: '摘要',
//     dataIndex: 'digest',
//     slots: { customRender: 'digest' },
//   },
//   {
//     title: '发生日期',
//     dataIndex: 'happenTime',
//     // key: 'paymentTime',
//     slots: { customRender: 'happenTime' },
//   },
//   {
//     title: '报销金额(元)',
//     dataIndex: 'expenseAmount',
//     slots: { customRender: 'expenseAmount' },
//   },
//   {
//     title: '发票金额(元)',
//     dataIndex: 'invoiceAmount',
//     slots: { customRender: 'invoiceAmount' },
//   },
//   {
//     title: '操作',
//     dataIndex: 'operation',
//     slots: { customRender: 'operation' },
//   },
// ];

export default defineComponent({
  components: {
    ProfileOutlined,
    DollarOutlined,
    uploadImgs,
    BranchesOutlined,
    PartitionOutlined,
    ExclamationCircleOutlined,
    travelDetailModal
  },
  props: {
    // applyVisible: {
    //   type: Boolean,
    //   default: false
    // },
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    // const store = useStore()
    const formRef = ref();
    const formState = reactive({
      theme: "",
      expenseType: undefined,
      invoiceType: [],
      evectionId: undefined,
      expenseCause: '',
      fileUrl: [],
      expenseAmount: 0,
      details: [{
        digest: undefined,
        happenTime: undefined,
        expenseAmount: undefined,
        invoiceAmount: undefined,
        fileUrl: []
      }],
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      resource: 1,
      content: "",
      //审批意见
      // annotation:[], //批注后文件
      revisePayroll: [],
      //修订后合同
      updateContractList: [],
      //修订后合同集
      reviseCon: [],
      //修改时需要修改修订后合同时用
      errorIsChecked: {
        expenseType: false,
        invoiceType: false,
        expenseCause: false,
        evectionId: false,
        fileUrl: false
      },
      itemId: null,
      errorChecked: [],
      state: 0
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      expenseCause: [{
        required: true,
        message: '报销事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      expenseType: [{
        required: true,
        message: '报销类型不能为空',
        trigger: 'change',
        type: 'number'
      }],
      invoiceType: [{
        required: true,
        message: '发票性质不能为空',
        trigger: 'change',
        type: 'array'
      }],
      evectionId: [{
        required: true,
        message: '出差审批不能为空',
        trigger: 'change',
        type: 'number'
      }],
      // fileUrl: [
      //   {
      //     required: true,
      //     validator: signFileurl,
      //     type: 'array',
      //     // trigger: 'change',
      //     trigger: formState.fileUrl.length == 0,
      //   },
      // ],
      revisePayroll: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.revisePayroll.length == 0
      }],
      reviseCon: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.reviseCon.length == 0
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        //详情
        return true;
      } else if (props.applyDetailData.state == 1) {
        //审批
        return true;
      } else if (props.applyDetailData.state == 2) {
        //修改
        return false;
      } else if (props.applyDetailData.state == 28) {
        //修订
        return true;
      } else if (props.applyDetailData.state == 29) {
        //财务编辑
        return true;
      }
    });
    const isShowChecked = computed(() => {
      if (props.applyDetailData.state === 0) {
        //详情
        return false;
      } else if (props.applyDetailData.state == 1) {
        //审批
        return false;
      } else if (props.applyDetailData.state == 2) {
        //修改
        return false;
      } else if (props.applyDetailData.state == 28) {
        //修订
        return false;
      } else if (props.applyDetailData.state == 29) {
        //财务编辑
        if (formState.resource) {
          return false;
        } else {
          return true;
        }
      }
    });
    const isDisabledForExpenseType = computed(() => {
      if (props.applyDetailData.state === 2) {
        //修改
        return false;
      } else {
        if (props.applyDetailData.state === 28 && isShowIcon.value('expenseType')) {
          return false;
        } else {
          return true;
        }
      }
    });
    const isDisabledForInvoiceType = computed(() => {
      if (props.applyDetailData.state === 2) {
        //修改
        return false;
      } else {
        if (props.applyDetailData.state === 28 && isShowIcon.value('invoiceType')) {
          return false;
        } else {
          return true;
        }
      }
    });
    const isDisabledForExpenseCause = computed(() => {
      if (props.applyDetailData.state === 2) {
        //修改
        return false;
      } else {
        if (props.applyDetailData.state === 28 && isShowIcon.value('expenseCause')) {
          return false;
        } else {
          return true;
        }
      }
    });
    const isDisabledForEvectionId = computed(() => {
      if (props.applyDetailData.state === 2) {
        //修改
        return false;
      } else {
        if (props.applyDetailData.state === 28 && isShowIcon.value('evectionId')) {
          return false;
        } else {
          return true;
        }
      }
    });
    const isDisabledForFileUrl = computed(() => {
      if (props.applyDetailData.state === 2) {
        //修改
        return false;
      } else {
        if (props.applyDetailData.state === 28 && isShowIcon.value('fileUrl')) {
          return false;
        } else {
          return true;
        }
      }
    });
    const isDisabledForTable = computed(() => {
      return key => {
        if (props.applyDetailData.state === 2) {
          //详情
          return false;
        } else {
          if (key === 1 && props.applyDetailData.state === 28) {
            return false;
          } else {
            return true;
          }
        }
      };
    });
    const isDisabledForPostil = computed(() => {
      return key => {
        if (props.applyDetailData.state === 2) {
          //详情
          return true;
        } else {
          if (key === 1 && props.applyDetailData.state === 29) {
            return false;
          } else {
            return true;
          }
        }
      };
    });

    const getData = async key => {
      const res = await selectExpenseApplyById(key);

      try {
        Object.assign(formState, res.data);
        formState.fileUrl = JSON.parse(formState.fileUrl);
        formState.invoiceType = formState.invoiceType.split(',');
        printData.value = [];
        let printData1 = [];
        let expenseAmountSum = 0;
        let printData2 = [{
          id: null,
          happenTime: '合计：',
          digest: null,
          invoiceAmount: null,
          expenseAmount: 800
        }, {
          id: null,
          happenTime: '申请人：',
          digest: null,
          invoiceAmount: '审批人:',
          expenseAmount: null
        }];
        formState.details.forEach((item, index) => {
          printData1.push({ ...item,
            id: index + 1,
            happenTime: moment(item.happenTime).format('YYYY-MM-DD')
          });
          item.key = index;
          item.happenTime = moment(item.happenTime, 'YYYY-MM-DD');
          item.fileUrl = item.fileUrl ? JSON.parse(item.fileUrl) : [];
          expenseAmountSum += item.expenseAmount;
        });
        printData2[0].expenseAmount = expenseAmountSum;
        printData2[1].digest = formState.applicantName;
        printData.value = [...printData1, ...printData2];
        formState.reviseCon = formState.updateContractList.length ? JSON.parse(formState.updateContractList[formState.updateContractList.length - 1].fileUrl) : [];
        formState.revisePayroll = formState.revisePayroll == null ? [] : formState.revisePayroll;
        formState.errorChecked = JSON.parse(formState.errorChecked);
        let isShowCheck = formState.details.some(item => item.isCheck == 1);

        if (isShowCheck) {
          formState.resource = 0;
        } else {
          formState.resource = 1;
        }

        getBelongEvection(res.data.applicantId, res.data.evectionId);
        console.log(formState);
      } catch (error) {
        console.log('报销详情:', error);
      }
    };

    const getTotalContractAmount = val => {
      val = `￥ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val;
    };

    const onDelete = key => {
      // formState.paymentRecords =formState.paymentRecords.filter(item => item.key !== key);
      formState.details.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        digest: undefined,
        happenTime: undefined,
        expenseAmount: undefined,
        invoiceAmount: undefined,
        fileUrl: []
      };
      formState.details.push(newData);
    }; //获取附件的url


    const getAdjuncts = val => {
      // console.log('getAdjuncts',val)
      formState.fileUrl = val;
    };

    const getAdjunctsForRev = val => {
      // console.log('getAdjuncts',val)
      formState.revisePayroll = val;
    };

    const getAdjunctsForRevCon = val => {
      // console.log('getAdjuncts',val)
      formState.reviseCon = val;
    }; //审批


    const handlePassOrReject = num => {
      formRef.value.validate().then(async () => {
        if (!formState.content && props.applyDetailData.state == 1) {
          _message.error('审批意见不能为空！');

          return;
        }

        if (!formState.content && props.applyDetailData.state == 29 && formState.resource == 0) {
          _message.error('说明不能为空！');

          return;
        }

        let param = [];

        if (props.applyDetailData.state == 29 && num == 0) {
          let arr = [];
          arr = Object.keys(formState.errorIsChecked).filter(value => {
            return formState.errorIsChecked[value];
          });
          let details = [];
          formState.details.forEach((item, index) => {
            if (item.isCheck) {
              let obj = {
                id: item.id,
                postil: item.postil,
                isCheck: item.isCheck
              };
              details.push(obj);
            }
          });

          if (arr.length == 0 && details.length == 0) {
            _message.error('请勾选报销问题项');

            return;
          }

          param = {
            applyId: formState.applyId,
            content: formState.content,
            state: num,
            belongProject: formState.belongProject,
            errorChecked: JSON.stringify(arr),
            details: details
          };
        } else {
          param = {
            applyId: formState.applyId,
            content: formState.content,
            state: num,
            belongProject: formState.belongProject
          };
        }

        loading.value = true;
        const res = await updateToExpenseApproval(param);

        if (res.code == 200) {
          _message.success(res.message);

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
        loading.value = false;
      });
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "审批责任人";

        case 4:
          return "财务审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //修改

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        if (!formState.details.length) {
          _message.error('报销明细不能为空');

          return;
        }

        let isOk = true;
        let details = [];
        formState.details.forEach((item, index) => {
          if (item.expenseAmount > item.invoiceAmount) {
            isOk = false;
            return;
          }

          let obj = {
            digest: item.digest,
            happenTime: item.happenTime.startOf('day').format('YYYY-MM-DD'),
            expenseAmount: item.expenseAmount,
            invoiceAmount: item.invoiceAmount,
            id: item.id,
            fileUrl: JSON.stringify(item.fileUrl) // isCheck:0,
            // postil:null
            // serialNo: index+1,

          };
          details.push(obj);
        });

        if (!isOk) {
          _message.error('报销金额要小于等于发票金额');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.details = details;
        obj.expenseAmount = expenseAmount.value;
        obj.invoiceType = obj.invoiceType.toString();

        if (obj.expenseType != 1) {
          obj.evectionId = null;
          obj.evectionTheme = null;
        } else {
          obj.evectionTheme = findEvectionLable(obj.evectionId);
        }

        loading.value = true;
        let res = await updateExpenseApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
        loading.value = false;
      });
    }; //修订


    const handleRevise = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        if (!formState.details.length) {
          _message.error('报销明细不能为空');

          return;
        }

        let isOk = true;
        let details = [];
        formState.details.forEach((item, index) => {
          if (item.expenseAmount > item.invoiceAmount) {
            isOk = false;
            return;
          }

          let obj = {
            digest: item.digest,
            happenTime: item.happenTime.startOf('day').format('YYYY-MM-DD'),
            expenseAmount: item.expenseAmount,
            invoiceAmount: item.invoiceAmount,
            isCheck: 0,
            postil: null,
            id: item.id,
            fileUrl: JSON.stringify(item.fileUrl) // serialNo: index+1,

          };
          details.push(obj);
        });

        if (!isOk) {
          _message.error('报销金额要小于等于发票金额');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.details = details;
        obj.expenseAmount = expenseAmount.value;
        obj.invoiceType = obj.invoiceType.toString();

        if (obj.expenseType != 1) {
          obj.evectionId = null;
          obj.evectionTheme = null;
        } else {
          obj.evectionTheme = findEvectionLable(obj.evectionId);
        }

        loading.value = true;
        let res = await updateReviseApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const findDataIsOk = (arr, i) => {
      if (i == 0) {
        return false;
      } else {
        if (new Date(arr[i].planPayTime.startOf('day').format('YYYY-MM-DD')) > new Date(arr[i - 1].planPayTime.startOf('day').format('YYYY-MM-DD'))) {
          return findDataIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    }; //修改成员


    const handleModifyTeamMember = async () => {
      const param = {
        teamMember: JSON.stringify(formState.teamMember),
        id: formState.applyId
      };
      const res = await updateTeamByApplyId(param);

      if (res.code == 200) {
        _message.success(res.message);
      } else {
        _message.error(res.message);
      }
    }; //修改模式是否显示最后一次修订合同是否可修改


    const isModifyForCot = index => {
      if (formState.updateContractList.length && props.applyDetailData.state == 2 && index == formState.updateContractList.length - 1) {
        return false;
      } else {
        return true;
      }
    }; //修改模式是否显示变更单是否显示可修改


    const isShowFileUrlByUp = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return false;
        } else {
          return true;
        }
      } else if (props.applyDetailData.state !== 2) {
        return false;
      }
    });
    const isShowFileUrl = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return true;
        } else {
          return false;
        }
      } else if (props.applyDetailData.state !== 2) {
        return true;
      }
    });
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        // console.log(selectedRowKeys,typeof selectedRowKeys)
        selectedRowKeys.forEach(item => {
          formState.details[item].isCheck = 1;
        });
      }
    }; //表头

    const columns = computed(() => {
      if (formState.resource) {
        return [{
          title: '序号',
          dataIndex: 'id',
          slots: {
            customRender: 'id'
          },
          width: 60
        }, {
          title: '摘要',
          dataIndex: 'digest',
          slots: {
            customRender: 'digest'
          },
          width: 300
        }, {
          title: '发生日期',
          dataIndex: 'happenTime',
          // key: 'paymentTime',
          slots: {
            customRender: 'happenTime'
          }
        }, {
          title: '发票金额(元)',
          dataIndex: 'invoiceAmount',
          slots: {
            customRender: 'invoiceAmount'
          }
        }, {
          title: '报销金额(元)',
          dataIndex: 'expenseAmount',
          slots: {
            customRender: 'expenseAmount'
          }
        }, {
          title: '文件',
          dataIndex: 'fileUrl',
          slots: {
            customRender: 'fileUrl'
          }
        }, {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }];
      } else if (formState.resource === 0 || props.applyDetailData.state == 28) {
        return [{
          title: '序号',
          dataIndex: 'id',
          slots: {
            customRender: 'id'
          },
          width: 60
        }, {
          title: '摘要',
          dataIndex: 'digest',
          slots: {
            customRender: 'digest'
          },
          width: 300
        }, {
          title: '发生日期',
          dataIndex: 'happenTime',
          // key: 'paymentTime',
          slots: {
            customRender: 'happenTime'
          }
        }, {
          title: '发票金额(元)',
          dataIndex: 'invoiceAmount',
          slots: {
            customRender: 'invoiceAmount'
          }
        }, {
          title: '报销金额(元)',
          dataIndex: 'expenseAmount',
          slots: {
            customRender: 'expenseAmount'
          }
        }, {
          title: '文件',
          dataIndex: 'fileUrl',
          slots: {
            customRender: 'fileUrl'
          }
        }, {
          title: '批注',
          dataIndex: 'postil',
          slots: {
            customRender: 'postil'
          }
        }];
      }
    }); //是否显示需要修订的字段

    const isShowIcon = computed(() => {
      return key => {
        let isShow = false;

        if (formState.errorChecked) {
          return isShow = formState.errorChecked.some(item => {
            return item === key;
          });
        } // console.log(isShow)

      };
    }); //出差审批查询

    const evectionOptions = ref([]);

    const getBelongEvection = async (applicantId, applyId) => {
      let res = await selectEvectionApply({
        applicantId,
        applyId
      });

      if (res.code === 200) {
        evectionOptions.value = [];
        if (res.data) res.data.forEach(item => {
          evectionOptions.value.push({
            value: item.id,
            label: item.theme
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //查看出差审批详情


    const travelApplyDetailData = reactive({
      state: 1,
      key: null,
      applyVisible: false
    }); //查看详情

    const toView = async (key, num) => {
      // console.log(item,num) //num 0详情 2修改 1审批 
      travelApplyDetailData.state = num;
      travelApplyDetailData.key = key;
      travelApplyDetailData.applyVisible = true;
    }; //关闭详情页


    const closetravelApplyVisible = val => {
      travelApplyDetailData.applyVisible = val.isClose;
    };

    const findEvectionLable = val => {
      let obj = evectionOptions.value.find(item => item.value === val);

      if (obj) {
        return obj.label;
      }
    }; //报销总金额


    const expenseAmount = computed(() => {
      let sum = 0;
      formState.details.forEach(item => {
        if (item.expenseAmount) {
          sum += item.expenseAmount;
        }
      });
      return sum;
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 8,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const labelNameData = ref([]); //获取标签

    const selectLabelByParam1 = async () => {
      labelNameData.value = [];
      let res = await selectExpenseTypeByParam({
        expenseTypeName: ''
      });

      if (res.code === 200) {
        let arr = [];
        res.data.forEach(item => {
          arr.push({
            value: item.id,
            label: item.expenseTypeName
          });
        });
        Object.assign(labelNameData.value, arr);
      } else {
        _message.error(res.message);
      }
    };

    const getDetailUrl = (val, index) => {
      // console.log(index,'getDetailUrl',val)
      formState.details[index].fileUrl = val;
      formRef.value.clearValidate([['details', index, 'fileUrl']]);
    }; //打印报销单


    let printData = ref([// {
      //   id:null,
      //   happenTime:null,
      //   digest:null,
      //   invoiceAmount:null,
      //   expenseAmount:null
      // },
      // {
      //   id:null,
      //   happenTime:'合计：',
      //   digest:null,
      //   invoiceAmount:null,
      //   expenseAmount:800
      // },
      // {
      //   id:null,
      //   happenTime:'申请人：',
      //   digest:null,
      //   invoiceAmount:'审批人:',
      //   expenseAmount:null
      // },
    ]);

    const handlePrint = () => {
      // 新建一个 DOM
      const div = document.createElement('div');
      const tableBox = 'tableBox';
      div.id = tableBox;
      const tableKey = ['id', 'happenTime', 'digest', 'invoiceAmount', 'expenseAmount'];
      const html = printData.value.map((item, index) => {
        return `<tr>
          ${tableKey.map(cItem => {
          if (cItem == 'digest' && index !== printData.value.length - 1) {
            return `<td>${item[cItem] === null ? "" : item[cItem]}</td>`;
          } else {
            return `<td class="print-td">${item[cItem] === null ? "" : item[cItem]}</td>`;
          }
        })}
        </tr>`;
      }).join('');
      const tableHtml = `<table width="100%" cellpadding="0" cellspacing="0" border="1">
        <tr>
          <th style="width:100px;">序号</th>
          <th style="width:180px;">时间</th>
          <th>费用说明</th>
          <th style="width:180px;">发票金额(元)</th>
          <th style="width:180px;">报销金额(元)</th>
        </tr>
        ${html}
      </table>`;
      div.innerHTML = `<h3 class="print-h">费用报销申请表</h3>${tableHtml}`;
      document.querySelector('body').appendChild(div);
      printJS({
        printable: 'tableBox',
        type: 'html',
        style: `
        .print-h {
          text-align:center;
          width:100%;
          margin:0
        }
        .print-td{
            text-align:center;
          }
        `
      });
      div.remove();
    };

    onMounted(() => {
      selectLabelByParam1();
      getApprovalItem();
      getData(props.applyDetailData.key);
    });
    return {
      formRef,
      formState,
      rules,
      isDisabledForCl,
      closeApplyVisible,
      columns,
      getTotalContractAmount,
      onDelete,
      handleAdd,
      getAdjuncts,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      handleOk,
      money,
      loading,
      getAdjunctsForRev,
      handleRevise,
      handleModifyTeamMember,
      isModifyForCot,
      isShowFileUrlByUp,
      isShowFileUrl,
      getAdjunctsForRevCon,
      rowSelection,
      isShowChecked,
      isShowIcon,
      isDisabledForExpenseType,
      isDisabledForInvoiceType,
      isDisabledForExpenseCause,
      isDisabledForFileUrl,
      isDisabledForTable,
      isDisabledForPostil,
      evectionOptions,
      toView,
      travelApplyDetailData,
      closetravelApplyVisible,
      isDisabledForEvectionId,
      expenseAmount,
      projectOptions,
      labelNameData,
      getDetailUrl,
      handlePrint,
      printData
    };
  }

});