import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch } from 'vue';
import useTablePagination from '@/hooks/pagination';
import uploadExl from '@/components/uploadExl.vue';
import { useStore } from 'vuex';
import Cookies from "js-cookie";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
import { selectHolidayRecordByPagination, selectHolidaySet, insertOrUpdateHolidaySet, selectHolidayHistoryByPagination } from '@/api/officeApi'; //表头

const columns = [{
  // title: '请假时间',
  dataIndex: 'time',
  width: '320px',
  slots: {
    customRender: 'time',
    title: 'customTitle'
  }
}, {
  title: '请假时长(天)',
  dataIndex: 'leaveDay',
  align: 'center'
}, {
  title: '请假类型',
  dataIndex: 'leaveType',
  slots: {
    customRender: 'leaveType'
  },
  align: 'center'
} // {
//   title: '处理方式',
//   dataIndex: 'leaveEffect',
//   slots: { customRender: 'leaveEffect' },
// },
];
const enterpriseOptions = [];

for (let i = 1; i < 29; i++) {
  enterpriseOptions.push({
    value: String(i)
  });
}

const monthOptions = [];

for (let i = 1; i < 13; i++) {
  monthOptions.push({
    value: String(i)
  });
}

export default defineComponent({
  components: {
    uploadExl,
    CaretUpOutlined,
    CaretDownOutlined
  },
  props: {
    uid: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    const formRef = ref();
    const formState = reactive({
      userName: "",
      idCard: ""
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false);
    const isShowUp = ref(0); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    }; //获取表格数据


    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        uid: props.uid
      };
      loading.value = true;

      try {
        const res = await selectHolidayRecordByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.uid
            });
            isShowUp.value = item.isShowUp;
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    };

    const store = useStore(); //是否是管理员

    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin); //是否可以初始导入

    const isFirstImport = computed(() => Number(Cookies.get('isFirstImport'))); //设置法定年假和企业带薪假

    const legalVisible = ref(false);
    const vacation = reactive({
      id: null,
      deadlineTime: "",
      updateDay: "",
      timeLong: "",
      dealWay: 0,
      isYearMonth: 0,
      month: "",
      day: "",
      yearMonth: "",
      yearDay: ""
    });
    const enterpriseVisible = ref(false);

    const handleClickLegal = async val => {
      const res = await selectHolidaySet();

      if (res.code === 200) {
        if (val == 1) {
          legalVisible.value = true;
          let arr1 = res.data.deadlineTime.split('-');
          vacation.yearMonth = arr1[0];
          vacation.yearDay = arr1[1];
          vacation.id = res.data.id;
        } else {
          enterpriseVisible.value = true;
          vacation.id = res.data.id;
          vacation.timeLong = res.data.timeLong;
          vacation.dealWay = res.data.dealWay;
          vacation.isYearMonth = res.data.isYearMonth;

          if (res.data.isYearMonth === 0) {
            vacation.updateDay = res.data.updateDay;
          } else {
            let arr = res.data.updateDay.split('-');
            vacation.month = arr[0];
            vacation.day = arr[1];
          }
        }
      } else {
        _message.error(res.message);
      }
    };

    const handleOkLegal = async () => {
      if (!vacation.yearMonth) {
        _message.error('截止日期月不能为空');

        return;
      }

      if (!vacation.yearDay) {
        _message.error('截止日期日不能为空');

        return;
      }

      const param = {
        id: vacation.id,
        deadlineTime: `${vacation.yearMonth}-${vacation.yearDay}`
      };
      const res = await insertOrUpdateHolidaySet(param);

      if (res.code === 200) {
        legalVisible.value = false;

        _message.success(res.message);
      } else {
        _message.error(res.message);
      }
    };

    const handleOkEnterprise = async () => {
      if (vacation.timeLong === null) {
        _message.error('请选择企业带薪年假时间');

        return;
      }

      if (vacation.timeLong === 0) {
        _message.error('企业带薪年假时间不能为0小时');

        return;
      }

      if (!vacation.updateDay && vacation.isYearMonth === 0) {
        _message.error('每月更新日期日不能为空');

        return;
      }

      if (!vacation.month && vacation.isYearMonth) {
        _message.error('每年更新日期月不能为空');

        return;
      }

      if (!vacation.day && vacation.isYearMonth) {
        _message.error('每年更新日期日不能为空');

        return;
      }

      const param = {
        id: vacation.id,
        updateDay: vacation.isYearMonth === 0 ? vacation.updateDay : `${vacation.month}-${vacation.day}`,
        timeLong: vacation.timeLong,
        isYearMonth: vacation.isYearMonth,
        dealWay: vacation.dealWay
      };
      const res = await insertOrUpdateHolidaySet(param);

      if (res.code === 200) {
        enterpriseVisible.value = false;

        _message.success(res.message);
      } else {
        _message.error(res.message);
      }
    }; //查看历史请假记录


    const historicalVisible = ref(false);
    const historicalTableData = ref([]);
    const historicalUid = ref('');
    let flag = ref(null); //分页信息

    const historical = useTablePagination(); //点击分页

    const onPageChangehistorical = pagination => {
      historical.current.value = pagination.current;
      getHistoricalTableData();
    };

    const getHistoricalTableData = () => {
      return new Promise((resolve, reject) => {
        const param = {
          pageNumber: historical.current.value,
          limit: historical.pageSize.value ? historical.pageSize.value : 10,
          uid: historicalUid.value,
          flag: flag.value
        };
        selectHolidayHistoryByPagination(param).then(res => {
          try {
            const arr = [];
            historicalTableData.value = [];
            if (res.data.list) res.data.list.forEach(item => {
              let time = item.startTime.split(",");
              arr.push({ ...item,
                time
              });
            });
            Object.assign(historicalTableData.value, arr);
            historical.total.value = res.data.countSize;
            historical.current.value = res.data.nowPage;
            historical.pageSize.value = res.data.pageSize;
          } catch (error) {
            console.log(error);
          }

          resolve(true);
        }).catch(error => {
          console.log('查询历史请假记录', error);
        });
      });
    };

    const clickFlag = val => {
      flag.value = val;
      getHistoricalTableData();
    };

    const toView = async key => {
      historicalUid.value = key;
      getHistoricalTableData().then(res => {
        try {
          historicalVisible.value = true;
        } catch (error) {
          console.log(error);
        }
      });
    };

    const dayOptions = computed(() => {
      return month => {
        let arr = [];
        let val = 30;

        switch (month) {
          case '1':
          case '3':
          case '5':
          case '7':
          case '8':
          case '10':
          case '12':
            val = 31;
            break;

          case '4':
          case '6':
          case '9':
          case '11':
            val = 30;
            break;

          case '2':
            val = 28;
            break;
        }

        for (let i = 1; i <= val; i++) {
          arr.push({
            value: String(i)
          });
        }

        return arr;
      };
    });

    const handleChange = value => {
      vacation.day = undefined;
    };

    const handleChange1 = value => {
      vacation.yearDay = undefined;
    };

    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      isCompanyAdmin,
      isFirstImport,
      legalVisible,
      handleClickLegal,
      vacation,
      handleOkLegal,
      enterpriseVisible,
      handleOkEnterprise,
      enterpriseOptions,
      toView,
      historicalVisible,
      historicalTableData,
      historical,
      onPageChangehistorical,
      historicalPaginationConfig: historical.paginationConfig,
      isShowUp,
      monthOptions,
      dayOptions,
      handleChange,
      handleChange1,
      flag,
      clickFlag
    };
  }

});