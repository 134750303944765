import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertExpenseApply, selectEvectionApply, selectExpenseTypeByParam } from '@/api/officeApi';
import travelDetailModal from './travelDetailModal.vue';
// import moment from 'moment';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
const columns = [{
  title: '序号',
  dataIndex: 'serialNo',
  slots: {
    customRender: 'serialNo'
  }
}, {
  title: '摘要',
  dataIndex: 'digest',
  slots: {
    customRender: 'digest'
  }
}, {
  title: '发生日期',
  dataIndex: 'happenTime',
  // key: 'paymentTime',
  slots: {
    customRender: 'happenTime'
  }
}, {
  title: '发票金额(元)',
  dataIndex: 'invoiceAmount',
  slots: {
    customRender: 'invoiceAmount'
  }
}, {
  title: '报销金额(元)',
  dataIndex: 'expenseAmount',
  slots: {
    customRender: 'expenseAmount'
  }
}, {
  title: '文件',
  dataIndex: 'fileUrl',
  slots: {
    customRender: 'fileUrl'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined,
    travelDetailModal
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      expenseType: undefined,
      invoiceType: [],
      evectionId: undefined,
      //出差审批
      expenseCause: '',
      fileUrl: [],
      expenseAmount: 0,
      details: [{
        digest: undefined,
        happenTime: undefined,
        expenseAmount: undefined,
        invoiceAmount: undefined,
        fileUrl: []
      }],
      itemId: null
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      expenseCause: [{
        required: true,
        message: '报销事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      expenseType: [{
        required: true,
        message: '报销类型不能为空',
        trigger: 'change',
        type: 'number'
      }],
      invoiceType: [{
        required: true,
        message: '发票性质不能为空',
        trigger: 'change',
        type: 'array'
      }],
      evectionId: [{
        required: true,
        message: '出差审批不能为空',
        trigger: 'change',
        type: 'number'
      }],
      // fileUrl: [
      //   {
      //     required: true,
      //     validator: signFileurl,
      //     type: 'array',
      //     // trigger: 'change',
      //     trigger: formState.fileUrl.length == 0,
      //   },
      // ],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //获取附件的url

    const getAdjuncts = val => {
      // console.log('getAdjuncts',val)
      formState.fileUrl = val;
    };

    const getDetailUrl = (val, index) => {
      // console.log(index,'getDetailUrl',val)
      formState.details[index].fileUrl = val;
      formRef.value.clearValidate([['details', index, 'fileUrl']]);
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        if (!formState.details.length) {
          _message.error('报销明细不能为空');

          return;
        }

        let isOk = true;
        let details = [];
        formState.details.forEach((item, index) => {
          if (item.expenseAmount > item.invoiceAmount) {
            isOk = false;
            return;
          }

          let obj = {
            digest: item.digest,
            happenTime: item.happenTime.startOf('day').format('YYYY-MM-DD'),
            expenseAmount: item.expenseAmount,
            invoiceAmount: item.invoiceAmount,
            fileUrl: JSON.stringify(item.fileUrl) // serialNo: index+1,

          };
          details.push(obj);
        });

        if (!isOk) {
          _message.error('报销金额要小于等于发票金额');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.details = details;
        obj.expenseAmount = expenseAmount.value;
        obj.invoiceType = obj.invoiceType.toString();

        if (obj.expenseType != 1) {
          obj.evectionId = null;
          obj.evectionTheme = null;
        } else {
          obj.evectionTheme = findEvectionLable(obj.evectionId);
        }

        loading.value = true;
        let res = await insertExpenseApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const onDelete = key => {
      // formState.paymentRecords =formState.paymentRecords.filter(item => item.key !== key);
      formState.details.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        digest: undefined,
        happenTime: undefined,
        expenseAmount: undefined,
        invoiceAmount: undefined,
        fileUrl: [] // serialNo:undefined,

      };
      formState.details.push(newData);
    };

    const getTotalContractAmount = val => {
      val = `￥ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val;
    }; //出差审批查询


    const evectionOptions = ref([]);
    const uid = Cookies.get('uid');

    const getBelongEvection = async () => {
      let res = await selectEvectionApply({
        applicantId: uid,
        applyId: null
      });

      if (res.code === 200) {
        evectionOptions.value = [];
        if (res.data) res.data.forEach(item => {
          evectionOptions.value.push({
            value: item.id,
            label: item.theme
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //查看出差审批详情


    const travelApplyDetailData = reactive({
      state: 1,
      key: null,
      applyVisible: false
    }); //查看详情

    const toView = async (key, num) => {
      // console.log(item,num) //num 0详情 2修改 1审批 
      travelApplyDetailData.state = num;
      travelApplyDetailData.key = key;
      travelApplyDetailData.applyVisible = true;
    }; //关闭详情页


    const closetravelApplyVisible = val => {
      travelApplyDetailData.applyVisible = val.isClose;
    };

    const findEvectionLable = val => {
      let obj = evectionOptions.value.find(item => item.value === val);

      if (obj) {
        return obj.label;
      }
    }; //报销总金额


    const expenseAmount = computed(() => {
      let sum = 0;
      formState.details.forEach(item => {
        if (item.expenseAmount) {
          sum += item.expenseAmount;
        }
      });
      return sum;
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 8,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const labelNameData = ref([]); //获取标签

    const selectLabelByParam1 = async () => {
      labelNameData.value = [];
      let res = await selectExpenseTypeByParam({
        expenseTypeName: ''
      });

      if (res.code === 200) {
        let arr = [];
        res.data.forEach(item => {
          arr.push({
            value: item.id,
            label: item.expenseTypeName
          });
        });
        Object.assign(labelNameData.value, arr);
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getBelongEvection();
      getApprovalItem();
      selectLabelByParam1();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      getAdjuncts,
      columns,
      onDelete,
      handleAdd,
      getTotalContractAmount,
      money,
      evectionOptions,
      toView,
      travelApplyDetailData,
      closetravelApplyVisible,
      expenseAmount,
      projectOptions,
      labelNameData,
      getDetailUrl
    };
  }

});